import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PlayerRankings.scss';
import { calculateExpPercentage } from './progress.js'; 

const PlayerRankings = () => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios('http://87.237.52.225:5410/api/players');
      setPlayers(result.data);
    };
    
    fetchData();
  }, []);

  return (
    <div className="App11">
      <div className="App2">
    <div className="table-wrapper">
      <h1>Player Rankings</h1>
      <table className="fl-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Class</th>
            <th>Level</th>
            <th>Guild</th>
            <th>Level Progress</th>
          </tr>
        </thead>
        <tbody>
        {players.map((player, index) => (
    <tr key={player.UID + "-" + index}>
              <td>{index + 1}</td>
              <td>{player.Name}</td>
              <td>{player.Class === 1 ? "Mage" : player.Class === 2 ? "Archer" : player.Class === 0 ? "Knight" : "Thief"}</td>
              <td>{player.Level}</td>
              <td>{player.GuildName === null ? "-":player.GuildName}</td>
              <td><progress className='bar' value={player.Exp === 0 ? 0 : calculateExpPercentage(player.Level, player.Exp)} max="100"></progress></td> 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
    
  );
};

export default PlayerRankings;