import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import mega from "../assets/img/mega.svg";
import google from "../assets/img/google.svg";

function generateCaptcha() {
  const num1 = Math.floor(Math.random() * 41) + 10; // Random number between 10 and 50
  const num2 = Math.floor(Math.random() * 41) + 10; // Random number between 10 and 50
  return {
    question: `${num1} + ${num2}`,
    answer: num1 + num2,
  };
}

export const Contact = () => {
  const formInitialDetails = {
    ID: '',
    PW: '',
    email: '',
    captchaAnswer: '',
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Submit');
  const [status, setStatus] = useState({});
  const [captcha, setCaptcha] = useState(generateCaptcha());
  
  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
  
    try {
      let response = await fetch("http://87.237.52.225:5401/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          ID: formDetails.ID,
          PW: formDetails.PW,
          email: formDetails.email
        }),
      });
  
      let result = await response.json();
      setFormDetails(formInitialDetails);
      if (result.code == 200) {
        alert('Registration successful');
      } else {
        alert(result.message);
      }
    } catch (error) {
      alert('Something went wrong, please try again later.');
    } finally {
      setButtonText("Submit");
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
        <Col size={12} md={6}>
            <TrackVisibility >
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2 style={{ textAlign: 'left' }}>Download</h2>
                <form onSubmit={handleSubmit}>
                  <Row >
                    <Col size={12} sm={6} className="px-1">
                    <div className="download1-icon">
            <h4><a href="https://mega.nz/file/xRFkiAqQ#Jr4fcGg0ZpzOOCicCbk9PcfYJhprXb-Oj6QMkRwbQto"target="_blank" rel="noopener noreferrer"><img src={mega} alt="Icon" style={{ width: '50px', height: '50px' }}/><span style={{ color: 'white' }}>  [NEW Client] Utopia - Online (Mega)</span> </a> </h4>
            </div>
            <br></br>
            <div className="download2-icon">
            <h4><a href="https://drive.google.com/file/d/1muCzcQijeO02CWzL6bJkKhSkWVfrgolH/view?usp=share_link"target="_blank" rel="noopener noreferrer"><img src={google} alt="Icon"style={{ width: '50px', height: '50px' }} /><span style={{ color: 'white' }}>  [NEW Client] Utopia - Online (Google Drive) </span></a></h4>
            </div>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2 style={{ textAlign: 'left' }}>Register</h2>
                <form onSubmit={handleSubmit}>
                  <Row className="App2">
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.ID} placeholder="ID" onChange={(e) => onFormUpdate('ID', e.target.value)} />
                      <input type="password" value={formDetails.PW} placeholder="Password" onChange={(e) => onFormUpdate('PW', e.target.value)}/>
                      <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
