import React, { useState } from 'react';
import './UserPanel.css';
import './Login.css'
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';



function UserPanel() {
  const [activeTab, setActiveTab] = useState('userInfo');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="App3">
        <div className="App2">
      <nav className="user-panel-nav">
        <button onClick={() => handleTabClick('userInfo')}>UserInfo</button>
        <button onClick={() => handleTabClick('unstuck')}>Unstuck</button>
        <button onClick={() => handleTabClick('guildTimeReset')}>Guild Time Reset</button>

      </nav>
    
      {activeTab === 'userInfo' && (
        <div className="user-info">
        <Col size={12} sm={6} className="px-1">
            <div className="info">
            <br></br>
          <p>ID: </p>
          <p>Email: </p>
          <p>IP: </p>
            </div>
        </Col>
        <Col size={12} sm={6} className="px-1">
        <div className="infoDB">
        <br></br>
          <p>12345</p>
          <p>user@example.com</p>
          <p>192.168.0.1</p>
            </div>
        </Col>
          <button>Change Password</button>
            
        </div>
      )}
    </div>
    </div>
  );
}

export default UserPanel;
