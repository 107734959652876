import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingScreen from './components/LoadingScreen';
import  PlayerRankings  from './components/PlayerRankings';



import { useEffect, useState, useCallback } from 'react';

/****UserPanel****/
import Login from './components/UserPanel/Login';
import UserPanel from './components/UserPanel/UserPanel';

function Home() {
  return (
    <div className="Home">
      
      <Banner />
      <Skills />
      <Contact />
      
    </div>
  );
}

function About() {
  return (
    <div className="About">
      <Login/>
    </div>
  );
}

function USP() {
  return (
    <div className="USP">
      <UserPanel/>
    </div>
  );
}


function Services() {
  return (
    <div className="Services">
      <h1>Services Page</h1>
    </div>
  );
}


/*function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
      </Switch>
    </Router>
  );
}
*/
function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <div className="App">
            <NavBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/index" element={<Home />} />
              <Route path="/rank" element={<PlayerRankings />} />
              <Route path="/USP" element={<Login />} />
              <Route path="/USP-L" element={<USP />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </div>
    </div>
  );
}




export default App;