// Define the level object
let level = {
    '1' : 0,
'2' : 5,
'3' : 24,
'4' : 60,
'5' : 160,
'6' : 328,
'7' : 548,
'8' : 814,
'9' : 1157,
'10' : 1558,
'11' : 2250,
'12' : 3086,
'13' : 4135,
'14' : 5444,
'15' : 7068,
'16' : 9126,
'17' : 11660,
'18' : 14769,
'19' : 18572,
'20' : 23214,
'21' : 29155,
'22' : 36409,
'23' : 45245,
'24' : 55988,
'25' : 69026,
'26' : 85569,
'27' : 105656,
'28' : 130009,
'29' : 159494,
'30' : 159150,
'31' : 238255,
'32' : 290216,
'33' : 352918,
'34' : 428488,
'35' : 519513,
'36' : 629149,
'37' : 761081,
'38' : 919784,
'39' : 1110624,
'40' : 1340045,
'41' : 1615778,
'42' : 1947099,
'43' : 2345141,
'44' : 2823265,
'45' : 3397501,
'46' : 4087087,
'47' : 4915108,
'48' : 5909267,
'49' : 7102808,
'50' : 8535623,
'51' : 10255633,
'52' : 12320243,
'53' : 14798389,
'54' : 17772795,
'55' : 21342730,
'56' : 25627317,
'57' : 30769501,
'58' : 36940820,
'59' : 44347118,
'60' : 53235407,
'61' : 63902104,
'62' : 76702906,
'63' : 92064653,
'64' : 110499551,
'65' : 132622249,
'66' : 169170373,
'67' : 191028978,
'68' : 229260177,
'69' : 275138508,
'70' : 330193415,
'71' : 396260232,
'72' : 1188853398,
'73' : 1426699190,
'74' : 1712116603,
'75' : 2054620005,
'76' : 2465627972,
'77' : 2958842501,
'78' : 3550695049,
'79' : 4260939963,
'80' : 5113193317,
'81' : 6135910416,
'82' : 7363228039,
'83' : 8835873647,
'84' : 10603329445,
'85' : 12723860419,
'86' : 15268923922,
'87' : 18322568824,
'88' : 21987686895,
'89' : 26385224274,
'90' : 31663104567,
'91' : 37993920972,
'92' : 45594437478,
'93' : 54712077691,
'94' : 65659881812,
'95' : 78786685050,
'96' : 94540297802,
'97' : 113442994895,
'98' : 136147038801,
'99' : 163398692810,  
'100' : 266078431385,
'101' : 325294117685,
'102' : 372352941185,
'103' : 468823529485,
'104' : 539647058885,
'105' : 588094117685,
'106' : 647712941185,
'107' : 711484235285,
'108' : 789781082385,
'109' : 819781082385,
'110' : 939781082385,
'111' : 1049781082385,
'112' : 2079781082385,
'113' : 3099781082385,
'114' : 4119781082385,
'115' : 5139781082385,
'116' : 6169781082385,
'117' : 7199781082385,
'118' : 8269781082385,
'119' : 9319781082385,
'120' : 1699781082385,
'121' : 1869781082385
    // Rest of your levels here...
};

function calculateExpPercentage(playerLevel, playerExp) {
    // Check if the player's level exists in the level object
    if (level.hasOwnProperty(playerLevel)) {
        // Calculate the percentage
        let percentage = (playerExp / level[playerLevel]) * 100;

        // Convert the percentage to an integer
        percentage = Math.floor(percentage);
      
        return percentage;
    } else {
        console.log(`Player's level ${playerLevel} not found in level object`);
        return null; // Or any other value you prefer when the level is not found
    }
}

export {
    calculateExpPercentage
};